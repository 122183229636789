import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { tw } from "twind";

import moment from "moment-timezone";
import { useQuery as reactUseQuery } from "react-query";
import { Tag } from "../../../@generated/graphql";
import { get } from "../../../utilities/restClient";
import TagsSelect from "../../Tags/tags.select.component";
import { clientFeatureStore } from "../../../store/client-features-store";

export const FilterPanel: FC<any> = (props) => {
  const { t } = useTranslation();
  const {
    setFilterDrawerOpen,
    interviewerFilterParams,
    setInterviewerFilterParams,
    hasCandidateAvailability,
    localFilters,
    setLocalFilters,
    debouncedRefetch,
    defaultFilterState
  } = props ?? {};

  const hasClientFeature = clientFeatureStore(
    (state) => state.hasClientFeature
  );

  const [filterData, setFilterData] = useState({
    ...interviewerFilterParams,
    ...localFilters
  });
  const { data: departmentsResponse } = reactUseQuery(
    ["CLIENT_DEPARTMENTS"],
    async () => {
      return get(`${import.meta.env.REACT_APP_URL_API}/tenants/departments`);
    }
  );

  return (
    <div className={tw` w-full`}>
      <Box
        sx={{
          m: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography color="primary" variant="h4">
          {t("ROOSTER.COMMON.FILTERS", { defaultValue: "Filters" })}
        </Typography>
        <IconButton
          onClick={() => {
            setFilterDrawerOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Stack direction="column" spacing={2} px={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography sx={{ width: 300 }}>
            {t("ROOSTER.COMMON.Consider-CANDIDATE-AVAILABILITY", {
              defaultValue: "Consider Candidate Availability"
            })}
          </Typography>
          <Switch
            disabled={!hasCandidateAvailability}
            checked={filterData?.considerCandidateAvailability}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                considerCandidateAvailability: e.target.checked
              });
            }}
            name="prioritizeCandidate"
            color="primary"
          />
          {!hasCandidateAvailability && (
            <Typography variant="subtitle1">
              Availability not provided.
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography sx={{ width: 300 }}>
            {t("ROOSTER.COMMON.CONSIDER-ONLY-SELECTED", {
              defaultValue: "Consider Only Selected Interviewers"
            })}
          </Typography>
          <Switch
            checked={filterData.onlyConsiderSelected}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                onlyConsiderSelected: e.target.checked
              });
            }}
            name="only-consider-selected"
            color="primary"
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography sx={{ width: 200 }}>
            {t("ROOSTER.COMMON.FILTER-BY-TIMEZONE", {
              defaultValue: "Filter by Timezones"
            })}
          </Typography>
          <FormControl sx={{ minWidth: 200 }}>
            <Autocomplete
              multiple
              sx={{ minWidth: 300, maxWidth: 300 }}
              options={moment.tz.names()}
              value={filterData.timezones ?? []}
              size="small"
              onChange={(_, values: string[]) => {
                setFilterData({
                  ...filterData,
                  timezones: values
                });
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </Stack>
        {/* Tags filter */}
        {hasClientFeature("tags") && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{ width: 200 }}>
              {t("ROOSTER.COMMON.FILTER-BY-TAGS", {
                defaultValue: "Filter by Tags"
              })}
            </Typography>
            <Stack direction="row" spacing={1}>
              <FormControl sx={{ width: 300 }}>
                <TagsSelect
                  fetchArchived={true}
                  value={filterData?.selectedTags ?? []}
                  onChange={(value: Tag[]) => {
                    setFilterData({
                      ...filterData,
                      tags: value.map((tag) => tag.id),
                      selectedTags: value
                    });
                  }}
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterData?.matchAnyTag}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        matchAnyTag: e.target.checked
                      });
                    }}
                    name="match-any-tag"
                    color="primary"
                  />
                }
                label="Match any Tag"
              />
            </Stack>
          </Stack>
        )}

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography sx={{ width: 200 }}>
            {t("ROOSTER.COMMON.FILTER-BY-DEPARTMENTS", {
              defaultValue: "Filter by Departments"
            })}
          </Typography>
          <Autocomplete
            multiple
            sx={{ minWidth: 300, maxWidth: 300 }}
            options={
              departmentsResponse?.data?.departments?.sort((a, b) =>
                a.localeCompare(b)
              ) ?? []
            }
            value={filterData?.departments ?? []}
            size="small"
            onChange={(_, values: string[]) => {
              setFilterData({
                ...filterData,
                departments: values
              });
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Stack>
      </Stack>

      <Box className={tw`fixed bottom-0 py-4 px-3 mt-auto right-0 w-[950px]`}>
        <Stack direction="row" justifyContent="right">
          <Button
            size="small"
            onClick={() => {
              setInterviewerFilterParams({
                ...defaultFilterState,
                considerCandidateAvailability: !!hasCandidateAvailability
              });
              setLocalFilters({
                timezones: [],
                page: 1
              });
              setFilterDrawerOpen(false);
            }}
            variant="text"
          >
            {t("ROOSTER.COMMON.CLEAR", {
              defaultValue: "Clear"
            })}{" "}
            {t("ROOSTER.COMMON.FILTERS", { defaultValue: "Filters" })}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            type="submit"
            onClick={() => {
              setInterviewerFilterParams({
                ...interviewerFilterParams,
                departments: filterData.departments,
                tags: filterData.tags,
                selectedTags: filterData.selectedTags,
                considerCandidateAvailability:
                  filterData.considerCandidateAvailability,
                matchAnyTag: filterData.matchAnyTag,
                onlyConsiderSelected: filterData.onlyConsiderSelected
              });
              setLocalFilters({
                timezones: filterData.timezones,
                page: 1
              });
              debouncedRefetch();
              setFilterDrawerOpen(false);
            }}
            size="small"
            variant="contained"
          >
            {t("ROOSTER.COMMON.APPLY", {
              defaultValue: "Apply"
            })}{" "}
            {t("ROOSTER.COMMON.FILTERS", { defaultValue: "Filters" })}
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default FilterPanel;
