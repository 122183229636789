import { useQuery, UseQueryResult } from "react-query";
import {
  AutobookViewApiPayload,
  AutobookViewApiResponse
} from "@rooster/types";
import { post } from "../../../utilities/restClient";
import { GCF } from "../../../constants/dbQuery";

const QUERY_KEY = "interviewers-availablility";

export const useGetAvailabilityForInterviewers = (
  params: {
    client: string;
    queryWindow: AutobookViewApiPayload["queryWindows"][0];
    requesterEmail: AutobookViewApiPayload["requesterEmail"];
    queryResources: AutobookViewApiPayload["queryResources"];
    tzid: AutobookViewApiPayload["responseFormat"]["tzid"];
    duration: number;
    page: number;
    showTentativeAsBusy: boolean;
    jobReqId: string;
  } | null
): UseQueryResult<AutobookViewApiResponse> => {
  const {
    client,
    queryWindow,
    requesterEmail,
    queryResources,
    tzid,
    page,
    showTentativeAsBusy = true,
    jobReqId,
    duration = 30
  } = params ?? {};
  return useQuery(
    [QUERY_KEY, params],
    async () => {
      return post(`${GCF.BASE_URL}/queryAvailability/view/people`, {
        client,
        requesterEmail,
        jobReqId,
        availabilityInterval: {
          value: duration,
          intervalStartsFrom: "startOfDay"
        },
        eventDuration: duration,
        queryWindows: [queryWindow],
        queryResources: queryResources,
        responseFormat: {
          showTentativeAsBusy,
          tzid,
          hideUnavailableSlot: false,
          hideParticipantsInSlot: true,
          removeMultiDaySlot: false,
          showSlotsInPast: true,
          showUnavailableDate: true,
          disableBufferBeforeAfterCheck: true,
          disableRestrictSchedulingBeforeCheck: true,
          type: "slot"
        },
        fetchMethod: ["freebusy.api", "events.api"],
        makeParticipantAsBusyWhenUnableToFetchEvents: true,
        skipInterviewerPreferencesSync: true
      });
    },
    {
      enabled: !!params,
      select: (data) => data.data
    }
  );
};
