import * as ROLES from "./../constants/roles";

const hasAccess = (authUser, targetRoles) => {
  return (
    authUser &&
    authUser.client &&
    authUser.roles &&
    targetRoles.some((role) => authUser.roles[role])
  );
};

const rolesCount = (authUser) => {
  return authUser.roles ? Object.keys(authUser.roles).length : 0;
};

const hasAccessToEmailTemplates = ({ authUser, clientDocData }) => {
  const defaultAllowedRoles = [
    ROLES.SCHEDULER_ONLY,
    ROLES.SCHEDULER,
    ROLES.SCHEDULING_ADMIN,
    ROLES.TENANT_ADMIN
  ];
  const allowedRoles = clientDocData?.settings?.roles_allowed_updating_templates
    ? clientDocData?.settings?.roles_allowed_updating_templates?.map(
        (role) => ROLES[role?.value]
      )
    : defaultAllowedRoles;
  return hasAccess(authUser, allowedRoles);
};

const hasAccessToInterviewersDashboard = (authUser) =>
  hasAccess(authUser, [
    ROLES.TENANT_ADMIN,
    ROLES.SCHEDULING_ADMIN,
    ROLES.SCHEDULER,
    ROLES.SCHEDULER_ONLY
  ]);

const hasAccessToDashboard = (authUser) =>
  hasAccess(authUser, [
    ROLES.TENANT_ADMIN,
    ROLES.SCHEDULING_ADMIN,
    ROLES.SCHEDULER,
    ROLES.SCHEDULER_ONLY
  ]);

const hasAccessToPreferences = (authUser) =>
  hasAccess(authUser, [
    ROLES.TENANT_ADMIN,
    ROLES.SCHEDULING_ADMIN,
    ROLES.SCHEDULER,
    ROLES.SCHEDULER_ONLY
  ]);

const hasNonRoosterLinksRole = (authUser) =>
  hasAccess(authUser, [
    ROLES.TENANT_ADMIN,
    ROLES.SCHEDULING_ADMIN,
    ROLES.SCHEDULER,
    ROLES.SCHEDULER_ONLY
  ]);

const hasAccessToUsersSection = (authUser) =>
  hasAccess(authUser, [ROLES.TENANT_ADMIN]);

const hasAccessToSettings = (authUser) =>
  hasAccess(authUser, [ROLES.TENANT_ADMIN]);

const hasAccessToRoosterLinks = (authUser) =>
  hasAccess(authUser, [ROLES.MEETING_SCHEDULER, ROLES.TENANT_ADMIN]);

const hasAccessToProspects = (authUser) =>
  hasAccess(authUser, [ROLES.SOURCER, ROLES.TENANT_ADMIN]);

const hasAccessToAnalytics = (authUser) =>
  hasAccess(authUser, [ROLES.TENANT_ADMIN]);

const hasSourcerRole = (authUser) => hasAccess(authUser, [ROLES.SOURCER]);
const hasMeetingSchedulerRole = (authUser) =>
  hasAccess(authUser, [ROLES.MEETING_SCHEDULER]);
const hasTenantAdminRole = (authUser) =>
  hasAccess(authUser, [ROLES.TENANT_ADMIN]);
const hasSchedulingAdminRole = (authUser) =>
  hasAccess(authUser, [ROLES.SCHEDULING_ADMIN]);
const hasSchedulerRole = (authUser) => hasAccess(authUser, [ROLES.SCHEDULER]);
const hasSchedulerOnlyRole = (authUser) =>
  hasAccess(authUser, [ROLES.SCHEDULER_ONLY]);

const hasOnlySourcerRole = (authUser) =>
  hasSourcerRole(authUser) && rolesCount(authUser) === 1;

const hasOnlyMeetingSchedulerRole = (authUser) =>
  hasMeetingSchedulerRole(authUser) && rolesCount(authUser) === 1;

const hasbothSourcerAndMeetingSchedulerRole = (authUser) =>
  hasMeetingSchedulerRole(authUser) &&
  hasSourcerRole(authUser) &&
  rolesCount(authUser) === 2;

const hasOnlySchedulerRole = (authUser) =>
  hasSchedulerRole(authUser) && rolesCount(authUser) === 1;

const hasOnlySchedulerOnlyRole = (authUser) =>
  hasSchedulerOnlyRole(authUser) && rolesCount(authUser) === 1;

const hasOnlySchedulerAdminRole = (authUser) =>
  hasSchedulingAdminRole(authUser) && rolesCount(authUser) === 1;

const hasSchedulerRoles = (authUser) =>
  hasSchedulerOnlyRole(authUser) ||
  hasSchedulerRole(authUser) ||
  hasSchedulingAdminRole(authUser);

const hasAccessToViewTemplateTypeDuringEmailTemplateCreation = (authUser) =>
  hasTenantAdminRole(authUser) ||
  (!(
    hasOnlySourcerRole(authUser) ||
    hasOnlyMeetingSchedulerRole(authUser) ||
    hasbothSourcerAndMeetingSchedulerRole(authUser)
  ) &&
    !(
      hasOnlySchedulerOnlyRole(authUser) ||
      hasOnlySchedulerAdminRole(authUser) ||
      hasOnlySchedulerRole(authUser)
    ));

export {
  hasAccess,
  hasTenantAdminRole,
  hasSourcerRole,
  hasMeetingSchedulerRole,
  hasSchedulingAdminRole,
  hasSchedulerRole,
  hasSchedulerOnlyRole,
  hasAccessToAnalytics,
  hasAccessToDashboard,
  hasAccessToEmailTemplates,
  hasAccessToInterviewersDashboard,
  hasAccessToPreferences,
  hasAccessToRoosterLinks,
  hasAccessToSettings,
  hasAccessToUsersSection,
  hasAccessToViewTemplateTypeDuringEmailTemplateCreation,
  hasOnlySourcerRole,
  hasOnlyMeetingSchedulerRole,
  hasSchedulerRoles,
  hasbothSourcerAndMeetingSchedulerRole,
  hasNonRoosterLinksRole,
  hasAccessToProspects
};
