import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import * as ROUTES from "../constants/routes";

type Preferences = {
  autobook: {
    defaultTab: "autobook" | "availability";
  };
  defaultDashboardView: ROUTES.JOBS | ROUTES.INTERVIEWS_CALENDAR;
  insights: {
    displayExpirationTime: null | number;
    snoozeDuration: number;
    defaultFilterView: string;
  };
  sideNav: {
    defaultSelected: string;
    closedGroups: { [key: string]: boolean };
  };
};

interface UserLocalState {
  preferences: Preferences;
  setPreferences: (preferences: Preferences) => void;
  setAutobookDefaultTab: (tab: Preferences["autobook"]["defaultTab"]) => void;
  setDefaultDashboardView: (view: Preferences["defaultDashboardView"]) => void;
  setInsightsFilterView: (view: string) => void;
  setInsightsSnoozeTime: (
    duration: Preferences["insights"]["snoozeDuration"]
  ) => void;
  setSideNavPrefs: (prefs: Preferences["sideNav"]) => void;
}

const insightsDefaults: Preferences["insights"] = {
  displayExpirationTime: null,
  snoozeDuration: 0,
  defaultFilterView: "mine"
};

export const useUserLocalStore = create<UserLocalState>()(
  devtools(
    persist(
      immer((set) => ({
        preferences: {
          autobook: {
            defaultTab: "autobook"
          },
          sideNav: {
            defaultSelected: "/preferences/autobook",
            closedGroups: {}
          },
          defaultDashboardView: ROUTES.JOBS,
          insights: {
            displayExpirationTime: null,
            snoozeDuration: 0,
            defaultFilterView: "mine"
          }
        },
        setPreferences: (preferences) => set({ preferences }),
        setAutobookDefaultTab: (tab: Preferences["autobook"]["defaultTab"]) =>
          set((state) => {
            state.preferences.autobook.defaultTab = tab;
          }),
        setSideNavPrefs: (prefs: Preferences["sideNav"]) =>
          set((state) => {
            state.preferences.sideNav = prefs;
          }),
        setInsightsFilterView: (view: string) =>
          set((state) => {
            if (!state.preferences.insights){
              state.preferences.insights = {...insightsDefaults};
            }
            state.preferences.insights.defaultFilterView = view;
          }),
        setDefaultDashboardView: (view: Preferences["defaultDashboardView"]) =>
          set((state) => {
            state.preferences.defaultDashboardView = view;
          }),
        setInsightsSnoozeTime: (
          duration: Preferences["insights"]["snoozeDuration"]
        ) =>
          set((state) => {
            const displayExpirationTime = duration * 60 * 1000 + Date.now();
            if (!state.preferences.insights) {
              state.preferences.insights = {
                displayExpirationTime: displayExpirationTime,
                snoozeDuration: duration,
                defaultFilterView: "mine"
              };
            } else {
              state.preferences.insights.snoozeDuration = duration;
              state.preferences.insights.displayExpirationTime =
                duration * 60 * 1000 + Date.now();
            }
          })
      })),
      {
        name: "user-local-preferences",
        storage: createJSONStorage(() => localStorage)
      }
    ),
    { enabled: false }
  )
);
